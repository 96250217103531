#sponsors {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(rgba(45, 3, 11, 0.95), rgba(196, 162, 91, 0.95)), url('../img/sponsors/sponsors_bg_img_1.jpg') bottom no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow-x: hidden;
    padding-bottom: 130px;
}

#sponsors .sp-header .ui.divider {
    width: 80vw;
}

#sponsors h1, #sponsors h2, #sponsors h3, #sponsors h4, #sponsors h5 {
    font-family: 'Open Sans', 'Lato';
}

#sponsors .sp-header {
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 20px 0px;    
}

#sponsors .sp-header h2{
    padding-bottom: 0px;
}

#sponsors .sp-header h3{
    font-size: 2.2rem;
    margin-top: 5px !important;
}

#sponsors .sp-header .p-desc {
    align-self: flex-start;
}

#sponsors .sponsor-logos {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 20px 15px;
}

#sponsors .sponsor-logos .ui.divider {
    width: 80vw;
}

#sponsors .sponsor-logos .main-sponsors,
#sponsors .sponsor-logos .secondary-sponsors {
    min-width: 35vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

#sponsors .sponsor-logos .secondary-sponsors {
    margin-bottom: 20px;
}

#sponsors .sponsor-logos .main-sponsors .m-sp {
    min-height: 150px;
    min-width: 450px;
    margin-bottom: 20px;
}

#sponsors .sponsor-logos .secondary-sponsors .s-sp {
    min-height: 160px;
    min-width: 250px;
    margin-top: 20px;

}

#sponsors .sponsor-logos .own-sponsors {
    width: 80vw;
    color: #2d030b;
}

#sponsors .sponsor-logos .own-sponsors .flash-photo {
    background: url('../img/sponsors/flashPhoto.png') center no-repeat;
    background-size: contain;
    min-height: 120px;
    min-width: 120px;
    margin-top: 20px;
}

#sponsors .sponsor-logos .main-sponsors .bemnt-tiles {
    background: url('../img/sponsors/bemnt-tiles.jpg') center no-repeat;
    background-size: contain;
    margin-right: 20px;
}

#sponsors .sponsor-logos .main-sponsors .med-practice {
    background: url('../img/sponsors/med-prac.png') center no-repeat;
    background-size: contain;
}

#sponsors .sponsor-logos .secondary-sponsors .fintax {
    min-height: 120px;
    min-width: 200px;
    background: url('../img/sponsors/finTax.jpg') center no-repeat;
    background-size: contain;
}


/* #sponsors .sponsor-logos .secondary-sponsors .aussie {
    min-height: 185px;
    min-width: 350px;
    background: url('../img/sponsors/aussie.jpg') center no-repeat;
    background-size: contain;
} */

#sponsors .sponsor-logos .secondary-sponsors .rma {
    min-height: 90px;
    min-width: 150px;
    background: url('../img/sponsors/rma.jpg') center no-repeat;
    background-size: contain;
}


@media (max-width:800px) {
    #sponsors {
        flex-direction: column;
        align-items: center;
        padding: 0 5vw;
        padding-top: 30px;
    }

    #sponsors .divider-img {
        min-width: 25px;
        min-height: 25px;
    }

    #sponsors .sponsor-logos {
        width: 90vw;
        padding: 20px 15px;
        border-radius: 20px;
        margin-bottom: 35px;
    }

    #sponsors .sponsor-logos .p-desc {
        font-size: 1.3rem;
    }

    #sponsors .sp-header h2 {
        text-align: center;
        margin-top: 40px;
    }

    #sponsors .sp-header .p-desc {
        text-align: center;
    }

    #sponsors .sp-header h3 {
        font-size: 1.5rem;
        text-align: center;
    }

    #sponsors .sponsor-logos .main-sponsors .m-sp {
        min-height: 10vh;
        max-width: 90vw;
        margin-bottom: 30px;
    }

    #sponsors .sponsor-logos .secondary-sponsors .s-sp {
        min-height: 6vh;
        max-width: 80vw;
        margin-bottom: 20px;
    }

    #sponsors .sponsor-logos .own-sponsors .flash-photo {
        min-height: 10vh;
        max-width: 80vw;
    }

    #sponsors .sponsor-logos .secondary-sponsors .fintax {
        min-height: 3.2vh;
    }

    #sponsors .sponsor-logos .secondary-sponsors .rma {
        min-height: 5.2vh;
    }

    #sponsors .sponsor-logos .main-sponsors,
    #sponsors .sponsor-logos .secondary-sponsors {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #sponsors .sponsor-logos .secondary-sponsors .aussie {
        min-height: 85px;
        min-width: 250px;
    }

    #sponsors .sp-quote {
        margin: 40px 0px;
    }

    #sponsors .sp-quote h3{
        font-size: 1.5rem;
    }
}