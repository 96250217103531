
.gallery {    
    min-height: 100vh;
    background: linear-gradient(rgba(45, 3, 11, 0.95), rgba(196, 162, 91, 0.95)), url('../img/gallery/gal_img_2.jpg') center no-repeat;
    background-size: cover;   
    display: flex;
    justify-content: center;
    align-items: center;    
    background-attachment: fixed;    
}

.gallery-container {
    position: relative;
    column-count: 3;
    padding: 15px;
    width: 90%;
    margin: auto;
}

.gal-img {
    display: inline-block;
    overflow: hidden;
}

.gal-img img {
    width: 100%;
    transition: all 0.25s ease-out;
    border-radius: 15px;
}

.gal-img:hover img {
    cursor: pointer;
    transform: scale(1.1);
}

.gallery .ui.modal>.content {
    display: flex !important;
    justify-content: center;
    background: rgba(0, 0, 0, 0.9) !important;
    background-image: linear-gradient(to right, #2d030b88, #c4a25b88) !important;
}

.ui.modal>.content>img {
    max-width: 100%;
    max-height: 90vh;
}


@media (max-width: 600px) {
    .gallery {
        background-attachment: fixed;
        background-size: cover;
    }

    .gallery-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

