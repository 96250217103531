#news {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    background: linear-gradient(#c4a25b99, #ffffff99);
    background-color: #fff;
    color: #2d030b;
}

#news .sub-header {
    margin-top: 50px;
    margin-bottom: 30px;
}

#news .p-desc {
    padding: 5px 0px;
}

#news .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 80vw;
    margin: auto;
    padding-bottom: 20px;
}

#news .content-container .end-of-season img{
    width: 19.5vw;
    border-radius: 10px;
}

#news .content-container .semi-qualify img{
    width: 26vw;
    border-radius: 10px;
}



#news .content-container .event-container.ssg-zzl,
#news .content-container .event-container.launch {
    margin-top: 50px;
}

#news .content-container.news-covid h3.second-sub-header{
    padding-bottom: 20px;
}

#news .content-container.news-events h3.second-sub-header.launch {
    padding-top: 50px;
}

#news .content-container .small-sub {
    margin-bottom: 15px;
}

#news .news-img-container.aurudu-kumara img {
    max-width: 40vw;
    border-radius: 10px;
}

#news .content-container.news-coach img {
    max-width: 30vw;
    border-radius: 10px;
}

#news .content-container.news-coach img,
#news .news-img-container {
    align-self: center;
    margin: 15px 0px 5px 0px;
}

#news .news-img-container.section2 {
    margin-top: 5px;
}

#news h2.sub-header {
    margin-top: 50px;
}

#news .small-sub {
    padding-top: 0px;
}

#news .news-latest .sp-text {
    font-weight: 500;
}

#news .news-latest .header-community-cup,
#news .news-latest .header-religeous,
#news .news-latest .header-cap {
    margin-top: 40px;
}

#news .news-img-container {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

#news .news-img-container.community-cup img,
#news .news-img-container.religeous-activity img,
#news .news-img-container.cap-ceremony img   {
    max-width: 19.5vw;
    border-radius: 10px;
}

#news .news-img-container.event-img-container1 img {
    max-width: 80vw;
    border-radius: 10px;
}

#news .news-img-container.event-img-container2 img {
    max-width: 26.3vw;
    border-radius: 10px;
}

#news .ui.divider {
    width: 80% !important;
}

@media (max-width:800px) {
    #news .sub-header.header-announce {
        margin-top: 80px;
    }

    #news .p-desc {
        text-align: justify;
    }

    #news .news-img-container {
        flex-direction: column;
        margin: 15px 0px 0px 0px;
    }

    #news .content-container .semi-qualify img,
    #news .content-container .end-of-season img{
        width: 80vw;
        border-radius: 10px;
    }

    #news .news-img-container.section2 {
        margin: 0px;
    }

    #news .content-container.news-coach img,
    #news .news-img-container.aurudu-kumara img,
    #news .news-img-container.community-cup img,
    #news .news-img-container.religeous-activity img,
    #news .news-img-container.cap-ceremony img,
    #news .news-img-container.event-img-container2 img {
        max-width: 80vw;
    }

    #news .news-img-container.event-img-container2 img {
        max-width: 80vw;
    }

    #news .divider-img {
        min-width: 25px;
        min-height: 25px;
    }
}