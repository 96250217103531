.header-menu {
    position: fixed;
    z-index: 5;
    width: 100%;
}

.btn-background {
    z-index: 10;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 20px 5px 3px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(212,175,55, 0.2);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    z-index: 100; 
    transform: scaleX(1) scaleY(1);   
    transition: all 0.6s ease-in-out;
}

.btn-background:active {
    transform: scaleX(1.2) scaleY(1.2); 
}

.full-btn {
    position: absolute;
    top: 10px;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn-logo {
    z-index: 10;
    width: 40px;
    height: 40px;
    margin-top: 3px;
    background: url('../png_logo_NSL_70px.png') no-repeat center;
    background-size: cover;
    transform: scaleX(1) scaleY(1);
    transition: all 0.6s ease-in-out;    
}

.btn-logo:active {
    transform: scaleX(0.2) scaleY(0.2);
}

.menu-btn {
    z-index: 10;
    cursor: pointer;
    transition: all 0.5s ease-out;
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.btn-line {
    width: 35px;
    height: 3px;
    margin: 3px 0 3px 0;
    background: #fff;
    transition: all 0.5s ease-out;
}

.menu-btn.spin .btn-line.line1 {
    transform: rotate(225deg) translate(-6px, -6px);
}

.menu-btn.spin .btn-line.line2 {
    opacity: 0;
}

.menu-btn.spin .btn-line.line3 {
    transform: rotate(-225deg) translate(-6px, 7px);
}
/* ********* MainMenu ******** */

.main-menu {
    position: fixed;
    top: 0;
    width: 100%;
    visibility: hidden;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.main-menu.show {
    visibility: visible;
}

.main-menu .menu-branding, 
.main-menu .menu-nav {
    height: 100vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.main-menu .menu-nav {
    background-color: rgba(58,58,58, 1);
    background-image: linear-gradient(to right, #2d030b88, #c4a25b88);
    transform: translate3d(0, -100%, 0);
    transition: all 0.6s ease-in-out;
}

.main-menu .menu-nav.show {
    transform: translate3d(0, 0, 0);
}

.main-menu .menu-branding {
    background-color: rgba(37,37,37, 1);
    transform: translate3d(0, 100%, 0);
    transition: all 0.6s ease-in-out;
}

.main-menu .menu-branding.show {
    transform: translate3d(0, 0, 0);
}

.main-menu .menu-branding .branding-logo {
    height: 250px;
    width: 250px;
}

/* -------------------- */

.main-menu .menu-desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0px 0px 5px;
    /* margin-top: 20px; */
    z-index: 3;
}

.main-menu .menu-desc .desc-vision,
.main-menu .menu-desc .desc-mission,
.main-menu .menu-desc .value-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.main-menu .menu-desc .desc-vision .desc-text,
.main-menu .menu-desc .desc-mission .desc-text,
.main-menu .menu-desc .value-container .desc-text {
    width: 100%;
    background-color: rgba(45, 3, 11, 0.5);
    background-image: linear-gradient(to right, #2d030b88, #c4a25b88);
    margin-bottom: 10px;
    padding: 10px 15px;
    border-radius: 15px;
    /* border: 1px solid #fff;  */
}

.main-menu .menu-desc .desc-vision .desc-text p,
.main-menu .menu-desc .desc-mission .desc-text .ui.list,
.main-menu .menu-desc .value-container .desc-text .ui.list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.main-menu .menu-desc .desc-vision .ui.label,
.main-menu .menu-desc .desc-mission .ui.label,
.main-menu .menu-desc .value-container .ui.label{
    font-size: 1.6rem;
    padding: 3px 10px;
    margin-bottom: 10px;
}

.main-menu .menu-desc .desc-vision p {
  font-size: 1.2rem;
  text-align: left;
  text-shadow: -0.5px 0.5px 0 #2d030b,
                0.5px 0.5px 0 #2d030b,
                0.5px -0.5px 0 #2d030b,
                -0.5px -0.5px 0 #2d030b;
}

.main-menu .menu-desc .desc-mission .desc-text .ui.list,
.main-menu .menu-desc .value-container .desc-text .ui.list {
    font-size: 1.2rem;
}

.main-menu .menu-desc .value-container .desc-text .ui.list a {
    color: #ff8c00;
}

.main-menu .menu-nav .nav-item {    
    transform: translate3d(600px, 0, 0);
    transition: all 0.5s ease-in-out;
}

.main-menu .menu-nav .nav-item h2 {
    font-size: 1.8rem;
    color: #fff;
    text-shadow:    -1.5px 1.5px 0 #2d030b,
                    1.5px 1.5px 0 #2d030b,
                    1.5px -1.5px 0 #2d030b,
                    -1.5px -1.5px 0 #2d030b;
    padding: 25px 0px;
}

.main-menu .menu-nav .fb-login {
    padding-top: 15px;
    transform: translate3d(600px, 0, 0);
    transition: all 0.5s ease-in-out;
}

.main-menu .menu-nav .nav-item.show {
    transform: translate3d(0, 0, 0);
}

.main-menu .menu-nav .nav-item.show#link1 {
    transition-delay: 0.2s;
}

.main-menu .menu-nav .nav-item.show#link2 {
    transition-delay: 0.3s;
}

.main-menu .menu-nav .nav-item.show#link3 {
    transition-delay: 0.4s;
}

.main-menu .menu-nav .nav-item.show#link4 {
    transition-delay: 0.5s;
}

.main-menu .menu-nav .nav-item.show#link5 {
    transition-delay: 0.6s;
}

.main-menu .menu-nav .nav-item.show#link6 {
    transition-delay: 0.7s;
}

.main-menu .menu-nav .nav-item.show#link7 {
    transition-delay: 0.8s;
}

.main-menu .menu-nav .fb-login.show {
    transform: translate3d(0, 0, 0);
    transition-delay: 0.6s;
}

.main-menu .menu-nav .nav-item h2.active {
    text-shadow:    -2.5px 2.5px 0 #2d030b,
                    2.5px 2.5px 0 #2d030b,
                    2.5px -2.5px 0 #2d030b,
                    -2.5px -2.5px 0 #2d030b;
    transform: scale(1.25, 1.25);
}

.main-menu .menu-nav .nav-item h2:hover {
    text-shadow:    -2.5px 2.5px 0 #2d030b,
                    2.5px 2.5px 0 #2d030b,
                    2.5px -2.5px 0 #2d030b,
                    -2.5px -2.5px 0 #2d030b;
    transform: scale(1.25, 1.25);
    transition: all 0.3s ease-in-out;
}

.ui.modal.values-modal>.content {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui.modal.values-modal>.content h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
}

.ui.modal.values-modal>.content h2 {
    font-size: 1.4rem;
    margin-top: 3px;
    margin-bottom: 10px;
    color: #d67c1c;
}

.ui.modal.values-modal>.content .p-desc {
    text-align: justify;
    margin-bottom: 15px;
}

/*    Footer   */
.footer-container {
    position: relative;
    width: 100%;
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 3;
    border-top: 3px solid #530b18;
    border-bottom: 3px solid #530b18;
    padding: 10px 100px;
    color: #2d030b;
}

.footer-container .footer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(68, 71, 71, 0.2);
    z-index: -5;
}

.footer-container .logo-copyright-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-container .logo-copyright-container .ui.image {
    max-width: 18%;
}

.footer-container .logo-copyright-container h4,
.footer-container .tech-msg-container h4{
    font-family: 'Lato';
    font-weight: 100;
    margin: 0;
    padding: 3px 5px;
}

.footer-container .footer-contacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-container .footer-contacts .p-desc {
    font-family: 'Lato', 'Open Sans';
    color: #2d030b;
    font-size: 1.5rem;
}

.footer-container .footer-contacts .p-desc:hover {
    color: darkslateblue;
}

.footer-container .footer-contacts .contact-socialmedia-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.3rem 0.8rem;
    z-index: 3;
}

.footer-container .logo-copyright-container {
    display: flex;
    flex-direction: row;
}

.footer-container .footer-contacts .contact-socialmedia-container a {
    color: #000;
    padding: 0.2rem;
}

.footer-container .footer-contacts .contact-socialmedia-container .icon-container {  
  padding: 7px 3px 6px 6px;
  margin-left: 5px;
  border-radius: 50%;
  background: #fff;
}

.footer-container .footer-contacts .contact-socialmedia-container .icon-container i.icon {
    color: #2d030b !important;
}

.footer-container .footer-contacts .contact-socialmedia-container .icon-container:hover {
    transform: scale(1.1, 1.1);
    transition: all 0.2s ease-out;
}



/* Screen Size: ............ */

/* Smaller Screens */
@media (max-width:800px) {
    .main-menu {
        display: flex;
        flex-direction: column-reverse;
    }

    .main-menu .menu-branding {
        justify-content: flex-start;
        z-index: 8;
        height: 65vh;
        width: 100vw;
        transform: translate3d(0, 1000px, 0);
        transition: all 0.7s ease-in-out;
    }

    .main-menu .menu-branding.show {
        transform: translate3d(0, 0, 0);
    }

    .main-menu .menu-nav.show {
        transform: translate3d(0, 0, 0);
    }    

    /* ****************** */

    .main-menu .menu-desc {
        padding: 5px 0px 0px 5px;
        margin-top: 0px;
        margin-bottom: 100px;
    }
    
    .main-menu .menu-desc .desc-vision,
    .main-menu .menu-desc .desc-mission,
    .main-menu .menu-desc .value-container {
      width: 95%;
      padding-top: 3px;
      border-radius: 15px;    
      margin-bottom: 0px;
    }

    .main-menu .menu-desc .desc-mission .desc-text {
        margin-bottom: 0px;
    }

    .main-menu .menu-desc .desc-vision .ui.brown.label,
    .main-menu .menu-desc .desc-mission .ui.brown.label,
    .main-menu .menu-desc .value-container .ui.brown.label{
        font-size: 1.2rem;
        margin-bottom: 1px;
    }

    .ui.labeled.icon.button.btn-values  {
        font-size: 1rem !important;
        margin-top: 3px;
        padding: 7px 30px 7px 15px !important;
    }

    .ui.modal.values-modal>.content h2 {
        margin-top: 8px;
    }
    

    .main-menu .menu-desc .desc-vision .desc-text,
    .main-menu .menu-desc .desc-mission .desc-text
    .main-menu .menu-desc .value-container .desc-text {
        margin-bottom: 0px;
        padding: 3px 7px;
        text-align: center;
    }

    .main-menu .menu-desc .desc-mission .desc-text .ui.list {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }

    .main-menu .menu-desc .desc-vision p {
        font-size: 0.8rem;
        line-height: 1.3rem;
        text-align: justify;
    }
    .main-menu .menu-desc .desc-mission .ui.list .item .content,
    .main-menu .menu-desc .value-container .ui.list .item .content {
        font-size: 0.8rem;
    }

    .main-menu .menu-nav {
        z-index: 6;
        height: 35vh;
        width: 100vw;
        transform: translate3d(0, -1000px, 0);
        transition: all 0.7s ease-in-out;
    }

    .main-menu .menu-nav .nav-item {
        font-size: 1.6rem;
        color: #fff;
        padding: 3px 0px;
    }

    .main-menu .menu-nav .nav-item h2 {
        font-size: 1.4rem;
        color: #fff;
        text-shadow:    -1px 1px 0 #2d030b,
                        1px 1px 0 #2d030b,
                        1px -1px 0 #2d030b,
                        -1px -1px 0 #2d030b;
        padding: 5px 0px;
    }

    .main-menu .menu-nav.show {
        transform: translate3d(0, 0, 0);
    }

    .main-menu .menu-branding .branding-logo {
        height: 120px;
        width: 120px;
        padding-top: 15px;
        margin-bottom: 5px;
    }

    .full-btn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .btn-logo {
        width: 40px;
        height: 40px;
        background-size: cover;
    }

    .footer-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 10px 30px;
    }

    .footer-container h4 {
        font-size: 0.85rem;
    }

    .footer-container .footer-contacts {
        margin: 5px 0px;
        order: 1;
    }

    .footer-container .footer-contacts .contact-socialmedia-container {
        padding: 0px;
    }

    .footer-container .logo-copyright-container {
        margin: 5px 0px;
        order: 2;
    }

    .footer-container .tech-msg-container {
        margin: 5px 0px;
        order: 3;
    }
}






