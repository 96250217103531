#theclub {
    min-height: 100vh;
    color: #2d030b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#c4a25b99, #ffffff99);
    background-color: #fff;
    overflow-x: hidden;
}

#theclub .page-header {
    margin-top: 50px;
}

#theclub .club-history .p-desc,
#theclub .president-message .p-desc {
    margin-top: 10px;
}

#theclub .club-history {
    margin-top: 60px;
}

#theclub .club-history .sub-header {
    margin-bottom: 40px;
}

.club-segment {
    width: 80vw;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#theclub .president-message .sub-header {
    margin-bottom: 30px;
}

#theclub .president-message .club-sub-segment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
}

.club-history .p-sub {
    align-self: flex-start;
}

#theclub .ui.divider {
    width: 80% !important;
    margin: 20px 0px 70px 0px;
}

#theclub .club-history .p-desc{    
    text-align: justify;
}

.club-history-img {
    max-width: 40vw;
    background: url('../img/theclub_clubhistory.jpg') center no-repeat;
    background-size: contain;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 2px solid #2d030b;
}

.club-history-img img {
    border-radius: 10px;
}

#theclub .president-message .club-sub-segment .p-left {
    align-self: flex-start;
}

.president-message .president-img {
    width: 300px;
    height: 300px;
    background: url('../img/president_img.jpg') top no-repeat;
    background-size: cover;
    border: 2px solid #2d030b;
    border-radius: 50%;
    float: left;
    shape-outside: circle();
    margin-bottom: 30px;
}

/* .office-bearers {
    margin-bottom: 50px;
} */

.office-bearers .p-desc {
    padding: 10px 0px;
}

#theclub .structure .nsl-structure-header{
    text-align: center;
    margin-bottom: 40px;
}

#theclub .structure .clubStructure-img {
    width: 1000px;
    height: 800px;
    background: url('../img/club-structure.jpg') center no-repeat;
    background-size: cover;
    border-radius: 20px;
    border: 2px solid #2d030b;
}

#theclub .ui.segment {
    width: 90%;
}

#theclub .second-sub-header {
    margin: 30px 0px 20px 0px;
    text-align: center;
}

#theclub .affi-logos {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 50px 0px 50px 0px;
}

#theclub .affi-logos .affi-logo {
    height: 125px;
    width:110px;
}

#theclub .affi-logos .affi-logo.actcricket {
    height: 145px;
    width:130px;
}

#theclub .affi-logos .affi-logo.cric-aus {
    height: 145px;
    width: 130px;
}

#theclub .affi-logos .affi-logo:hover {
    transform: scale(0.9);
    transition: all 0.2s ease-in-out;
}

#theclub .affi-logos .ccsca{
    background: url('../img/Affiliates/ccsca.png') center no-repeat;
    background-size: contain;
    margin-right: 5px;
}

#theclub .affi-logos .actcricket{
    background: url('../img/Affiliates/act-cricket.png') center no-repeat;
    background-size: cover;
    margin-top: 10px;
}

#theclub .affi-logos .cric-aus{
    background: url('../img/Affiliates/cric-aus.jpg') center no-repeat;
    background-size: contain;
    margin-top: 10px;
}

#theclub .affi-logos .iamsa{
    background: url('../img/Affiliates/iamsa.jpg') center no-repeat;
    background-size: contain;
    margin: 5px 0px 0px 5px;
}

@media (max-width: 800px) {
    #theclub .club-history {
        margin-top: 80px;
    }

    #theclub .club-history-img {
        max-width: 80vw;
    }

    #theclub .divider-img {
        min-width: 25px;
        min-height: 25px;
    }

    #theclub .president-message .sub-header {
        text-align: center;
    }

    .president-message .president-img {
        width: 250px;
        height: 250px;
    }

    #theclub .president-message .club-sub-segment {
        width: 80vw;
    } 
    
    #theclub .structure .clubStructure-img {
        width: 80vw;
        height: 30vh;
    }

    #theclub .affi-logos {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: 50px 0px 50px 0px;
    }
    
    #theclub .affi-logos .affi-logo {
        height: 70px;
        width: 60px;
    }

    #theclub .affi-logos .affi-logo.actcricket {
        height: 80px;
        width: 70px;
        margin-top: 13px;
    }

    #theclub .affi-logos .affi-logo.iamsa {
        height: 70px;
        width: 70px;
    }

    #theclub .affi-logos .affi-logo.cric-aus {
        height: 80px;
        width: 70px;
    }
}