#playerProfile,
#memberManagement,
#playerManagement,
#feesManagement {
    min-height: 100vh;
    color: #2d030b;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(#c4a25b99, #ffffff99);
    background-color: #fff;
    overflow-x: hidden;
}

#playerProfile .ui.form {
    margin: 50px;
}

#memberManagement .ui.form,
#playerManagement .ui.form,
#feesManagement .ui.form {
    width: 50vw;
    margin: 50px 50px;
}




#playerProfile .ui.form .field.selection-field{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#playerManagement .ui.dropdown,
#feesManagement .ui.dropdown {
    font-size: 1.5rem;
}

#memberManagement .ui.button,
#playerManagement .ui.button,
#feesManagement .ui.button {
    font-size: 1.2rem !important;
    color: #fff;
    padding: 20px 40px;
    background-color: #2d030b;
}

/* #playerProfile .ui.form .field>.selection.dropdown {
    width: 70%;
    margin: auto;
} */