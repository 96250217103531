.contacts-container * {
    box-sizing: border-box !important;
    margin: 0%;
    padding: 0%;
    font-family: 'Open Sans', sans-serif !important;
}

.contacts-container {
    width: 100vw;
    min-height: 100%;    
    /* background:  linear-gradient(rgba(46, 45, 46, 1), rgba(83, 81, 81, 0.8)), url('../img/gal_img_2.jpg') center no-repeat; */
    background: linear-gradient(rgba(136, 120, 85, 0.9), rgba(255, 255, 255, 0.9)), url('../img/contact-background.jpg') center no-repeat;
    /* background: linear-gradient(rgba(196, 162, 91, 0.6), rgba(255, 255, 255, 0.6)); */
    background-size: cover;
    background-attachment: fixed;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 50px;
}

/* .contact-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(15, 17, 17, 0.8);
} */

.contact-header, .contact-method-container, .send-message-container , .contacts-container .ui.divider{
    z-index: 2;
}

.contacts-container .contact-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px 0px;
}

.contacts-container .contact-header .contact-big-header h2 {
    font-family: 'Qwigley', 'Indie Flower', cursive !important;
    /* font-family: 'Irish Grover', cursive; */
    font-size: 3.5rem;
    color: #2d030b;
}

.contacts-container .contact-header .contact-big-header h4 {
    /* font-style: italic; */
    font-size: 1rem;
    color: #2d030b;
    font-weight: bold;
    float: right;
}

.contacts-container .contact-header .contact-big-header {
    margin-bottom: 20px;
}

.contacts-container .contact-small-header {
    margin-left: 10vw;
    align-self: flex-start;
}

.contacts-container .contact-small-header .p-desc {
    font-size: 1.5rem;
    color: #2d030b;
}

.contacts-container .ui.divider {
    width: 80% !important;
}

.contacts-container .ui.divider h2 {
    font-size: 1.5rem;
}


.contacts-container .ui.divider h2 i.icon {
    font-family: Icons !important;
}

.contacts-container .ui.divider .ui.header{
    color: #2d030b;
}

.contacts-container .contact-method-container {
    margin: 30px 0px 30px 0px;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}

.contacts-container .contact-method-container .icon-div {
    padding: 7px 3px 3px 6px;
    margin-left: 5px;
    color: #2d030b;
}

.contacts-container .contact-method-container .icon-div i.icon {
    font-family: Icons !important;
}

.contact-method-container h3:hover {
    transform: scale(0.9);
    cursor: pointer;
}

.contact-method-container h4 {
    color: #2d030b;
    text-align: center;
}

.contact-phone, .contact-email, .contact-facebook {
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contact-phone .phone-container {
    display: flex;
    flex-direction: row;
}



.contact-phone h3, .contact-email h3, .contact-facebook h3 {
    font-size: 1.5rem;
    color: #2d030b;
    /* text-shadow: -0.75px 0.75px 0 #fff,
        0.75px 0.75px 0 #fff,
        0.75px -0.75px 0 #fff,
        -0.75px -0.75px 0 #fff; */
}

.contact-phone h3:hover, .contact-email h3:hover, .contact-facebook h3:hover {
    color: midnightblue;   
}

.contact-phone h4, .contact-email h4, .contact-facebook h4 {
    font-size: 1.5rem;
    font-weight: 100;
}


.send-message-container {
    margin: 30px 0px;
    width: 80%;
}

.send-message-container .ui.form {
    width: 100%;
}

.send-message-container .ui.form .field>label {
    color: #2d030b;
    font-size: 1.3rem;
}

.send-message-container .ui.form input,
.send-message-container .ui.form textarea
{
    font-size: 1.3rem !important;
    background: rgba(255, 255, 255, 0.3) !important;
    border: 1px solid #2d030b !important;
    color: #2d030b !important;
}

.send-message-container .ui.button {
    font-size: 1.2rem !important;
    color: #fff;
    padding: 10px 30px;
    background-color: #2d030b;
}

.send-message-container .ui.button:hover {
    color: #2d030b;
    background-color: #777777;
}

.contacts-container .terms-cond {
    width: 80vw;
    margin-bottom: 10px;
}

.contacts-container .terms-cond .section-1{
    margin-bottom: 5px;
}

.contacts-container .terms-cond .p-desc {
    color: #2d030b;
    font-size: 0.9rem;
}

.contact-thankyou {
    height: 100vh;
    width: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-thankyou h2 {
    font-size: 1.8rem;
}

.contact-thankyou h3 {
    font-size: 1.4rem;
}


/* ===================================================== */

@media (max-width: 1000px) {
    .contacts-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: linear-gradient(rgba(136, 120, 85, 0.9), rgba(255, 255, 255, 0.9)), url('../img/contact-background_mobile.jpg') center no-repeat;
        background-size: cover;
        background-attachment: fixed;
        padding-top: 80px;
    }

    .contacts-container .ui.divider {
        width: 80% !important;
    }

    .contacts-container  .divider-img {
        min-width: 25px;
        min-height: 25px;
    }

    .contacts-container .contact-header {
        width: 75%;
        padding: 0px;
    }

    .contacts-container .contact-header .contact-big-header h2 {
        font-size: 1.75rem;
        color: #2d030b;
        font-style: italic;
        font-weight: 500;
    }
    
    .contacts-container .contact-header .contact-big-header h4 {
        font-size: 0.8rem;
        color: #2d030b;
        font-weight: bold;
        float: right;
    }
    
    .contacts-container .contact-header .contact-big-header {
        margin-bottom: 20px;
    }
    
    .contacts-container .contact-header .contact-small-header h3 {
        font-size: 2.4rem;
        color: #2d030b;
    }

    .contacts-container .contact-small-header .p-desc {
        font-size: 1.3rem;
    }

    .contact-phone .phone-container {
        display: flex;
        flex-direction: column;
    }

    .contact-phone .phone-container .slash{
        display: none;
    }

    .contacts-container .ui.divider {
        margin: 10px 0px 0px 0px;
    }

    .contacts-container .ui.divider .ui.header{
        font-size: 1.2rem;
    }

    .contacts-container .ui.divider::before,
    .contacts-container .ui.divider::after
    {
        transform: scaleY(2) !important;
    }

    .send-message-container .ui.button {
        font-size: 1.2rem !important;
        color: #fff;
        padding: 8px 25px;
        background-color: #2d030b;
    }
    
    .contact-header, .contact-method-container, .send-message-container {
        width: 90%;
    }

    .contact-header {
        word-wrap: break-word;
        text-align: center;
        margin: 10px 0px 0px 0px;
    }

    .contact-phone, .contact-email, .contact-facebook {
        margin: 0px 0px 20px 0px;
    }

    .contact-phone h3, .contact-email h3, .contact-facebook h3 {
        font-size: 1.4rem;
    }

    .contact-phone h4, .contact-email h4, .contact-facebook h4 {
        font-size: 1.3rem;
    }
    

    .contact-phone h4, 
    .contact-email h4, 
    .contact-facebook h4 {
        padding: 2px 5px;
        border-radius: 5px;
    }

    .contacts-container .contact-method-container {
        margin: 25px 0px 0px 0px;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
    }

    .send-message-container .ui.form {
        size: "huge" !important;
        width: 85%;
        margin: auto;
    }

    .contacts-container .terms-cond {
        width: 70vw;
        margin-bottom: 30px;
    }

    .contacts-container .terms-cond .p-desc{
        text-align: justify;
        font-size: 0.6rem;
    }

}