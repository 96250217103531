
#home-main {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
}

#home-main .name-logo {
    height: 300px;
    width: 400px;
    background: url('../img/name_logo.png') center no-repeat;
    background-size: contain;
    z-index: 2;
    transform: translateX(0);
    transition: all 0.5s ease-in-out;
    margin-bottom: 25vh;
}

#home-main .name-logo.hide {
  transform: translateX(-1000px);
}

#home-main .home-content {
    z-index: 3;
}

#home-main .home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20vh;
}

#home-main .home-content h2.sm-heading{
  font-family: 'Qwigley', 'Indie Flower', cursive !important;
  font-size: 3.5rem;  
}

#home-main .home-content .name-kumar{
  font-family: 'Open Sans';
  font-size: 1rem;
  align-self: flex-end;
  margin-top: 0px;
}

#home-main .home-content h2.welcome-msg{
  font-family: 'Lato', 'Open Sans';
  font-size: 2.8rem;
  padding-bottom: 50px;
  color: #c4a25b;
  transform: translateX(0);
  transition: all 0.5s ease-in-out;
}

#home-main .home-content h2.welcome-msg.hide {
  transform: translateX(1000px);
}

#home-main video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.9;
    z-index: 1;
    object-fit: cover;
}

#home-main .overlay {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 2;
    background: rgba(35, 35, 35, 0.3);
}

#home-main h1, #home-main h2 {
    font-weight: 400;
}

h1.lg-heading {
    position: absolute;
    top: 60px;
    left: 100px;
    z-index: 2;
    float: left;
    font-family: 'edo sz' !important;
    font-size: 6rem;
    padding: 1px 3px !important;
    margin-top: 0px !important;
    background-color: rgba(34, 36, 36, 0.5);
    width: fit-content;
    color: rgb(196, 162, 91);
    border-radius: 20px;
    text-shadow: -0.75px 0.75px 0 #fff,
                          0.75px 0.75px 0 #fff,
                         0.75px -0.75px 0 #fff,
                        -0.75px -0.75px 0 #fff;

    transform: translateX(0);
    transition: all 0.5s ease-in-out;
}

h2.sm-heading {
    font-family: "Lucida Console", "Courier New", monospace;
    color: #fff;
    font-size: 2.0rem;
    font-weight: bold;
    padding: 0.2rem 0.8rem;
    width: fit-content;
    text-shadow: -1px 1px 0 #2d030b,
                          1px 1px 0 #2d030b,
                         1px -1px 0 #2d030b,
                        -1px -1px 0 #2d030b;
    transform: translateX(0);
    transition: all 0.6s ease-in-out;
    border-radius: 10px;
}

.name-kumar {
  transform: translateX(0);
  transition: all 0.6s ease-in-out;
}

.lg-heading.hide {
    transform: translateX(1000px);
  }
  
.sm-heading.hide {
    transform: translateX(1000px);
  }
.name-kumar.hide {
  transform: translateX(1000px);
}  


  /* Smaller Screens */


  /* Screen Size: ............ */
  @media (max-width: 1000px) {
    #home-main {
      width: 100%;
      max-height: 100vh;
      padding: 0px 10px;
      overflow-y: auto;
    }

    #home-main .name-logo {
      height: 100px;
      width: 200px;
      margin-top: 0vh;
      margin-bottom: 0vh;
    }

    #home-main .home-content {
      width: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 0vh;
    }

    #home-main .home-content h2.welcome-msg {
      font-size: 1rem;
      margin-bottom: 22.5vh;
    }    

    #home-main .home-content .name-kumar{
      font-size: 0.7rem;
      margin-right: 10vw;
    }

    #home-main .home-content h2.sm-heading {
      width: 90% !important;
      margin-bottom: 5px !important;
      font-size: 1.75rem;
      padding: 3px 0px;
      width: fit-content;
      margin-top: 22.5vh;
    }

    h1.lg-heading {
      position: absolute;
      top: 2px;
      left: 5px;
      font-size: 2.7rem;
      left: 5px;
      margin: 30px 0px 0px 10px !important;
      text-shadow: -1px 1px 0 #fff,
                    1px 1px 0 #fff,
                    1px -1px 0 #fff,
                    -1px -1px 0 #fff;
      padding: 3px 2px 3px 15px !important;
      width: 52%;
      box-decoration-break: clone;
    }

    h2.sm-heading>.highlight {
      font-size: 1.8rem;
    }

    #home-main .home-content h2.welcome-msg {
      padding-bottom: 0px;
    }
}