/* 
@font-face {
  font-family: 'edo sz';
  src: local('edo sz'), url(./fonts/edosz.ttf) format('truetype')
}

@font-face {
  font-family: 'ANDYB';
  src: local('ANDYB'), url(./fonts/ANDYB.TTF) format('truetype');
} */

h1, h2, h3, h4, h5, .p-desc{
  box-sizing: border-box;
  margin: 0;
  padding: 0;  
  font-family: 'Open Sans', sans-serif !important;
}

* .ui.divider {
  color: #2d030b !important;
}

.App {
  height: 100vh;
  color: #fff;
  background-color: #444;
  /* font-family: 'ANDYB', Tahoma, Geneva, Verdana, sans-serif; */
  line-height: 1.5;
}

.page-header {
  font-size: 3.8rem;
}

.sub-header {
  font-family: 'Anton', sans-serif !important;
  font-weight: 100;
  font-size: 3rem;
}

.second-sub-header {
  font-size: 1.8rem;
}

.p-desc {
  font-size: 1.3rem;
  word-spacing: 0.02rem;
}

.divider-img {
  min-width: 40px;
  min-height: 40px;
  background: url('./img/cric-ball.png') center no-repeat;
  background-size: cover;
}